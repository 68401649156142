<template>
    <v-row justify="center" align-content-center>
        <v-col cols="12" md="6" lg="4">
            <v-card class="elevation-3 mx-2" dark color="secondary">
                <v-card-text>
                    <p class="text-center white--text display-1 mb-5 shadowed">Reset Your Password</p>
                    <v-alert class="mt-2" dense type="error" v-if="errors">
                        {{ errors }}
                    </v-alert>
                    <v-row dense class="mt-3">
                        <v-col cols="12">
                            <v-text-field
                                :autofocus="!email.length"
                                ref="email"
                                v-model="email"
                                outlined
                                prepend-inner-icon="fa fa-envelope"
                                label="Your Email Address"
                                type="email"
                                class="hide-empty-msgs"
                                :success="validEmail"
                                placeholder="Ex: your.name@gmail.com"
                                :rules="[rules.required, rules.email]"
                                validate-on-blur
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="password"
                                type="password"
                                outlined
                                label="New Password"
                                placeholder="Ex: partyPants$12"
                                prepend-inner-icon="fa fa-lock"
                                :rules="[rules.required, rules.minlength]"
                                :color="(validPassword) ? 'success' : ''"
                                :append-icon="(validPassword) ? 'fa fa-check-circle' : ''"
                                validate-on-blur
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <p class="text-center mb-0">
                        <v-btn large rounded :loading="sending" :disabled="!canLogin" @click.native="resetPassword()" class="mb-0 px-10" color="primary">Change Password</v-btn>
                    </p>
                    <v-divider class="mt-4 mb-3" />
                    <p class="text-center body-2 pb-0">
                        <router-link class="primary--text action-item" to="/login">Return to Login</router-link> 
                    </p>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'AuthLogin',
    computed: {
        ...mapState(['user', 'existingEmail']),
        canLogin(){
            return this.email && this.validEmail && this.validPassword;
        },
        validEmail(){
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(this.email);
        },
        validPassword(){
            return this.password && this.password.length > 5;
        },
        validPasswordConfirm(){
            return this.passwordConfirmation && this.passwordConfirmation == this.password;
        },
    },
    data: () => ({
        sending: false,
        email: '',
        loggedOut: false,
        password: '',
        passwordConfirmation: '',
        passwordError: null,
        token: null,
        errors: null,
        ready: false,
        rules: {
            required: value => !!value || 'Required.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Invalid e-mail.'
            },
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        }
    }),
    methods: {
        ...mapMutations(['setUser', 'setExistingEmail']),
        initData(){
            this.$appFn.setTitle("Reset Password - I'm Okay", true);
            this.token = this.$route.params.token;
            if(this.user){
                this.email = this.existingEmail || this.$route.query.e;
            }else{
                this.email = this.$route.query.e;
            }
            if(!this.token){
                this.errors = "Invalid token provided. Please check the link from your email.";
            }
        },
        resetPassword(){ 
            this.sending = true;
            this.errors = null;
            let request = {
                endpoint: '/auth/reset', 
                method: 'post',
                data: {
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password,
                    token: this.token
                },
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$eventHub.$emit('sending');
                    this.setExistingEmail(res.data.email);
                    this.setUser(res.data);
                    location.href = '/app';
                }else{
                    this.errors = "Something went wrong! Please try again.";
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.sending = false;
            });
        },
        checkPassMatch(){
            this.passwordError = null;
            if(this.password !== this.passwordConfirmation){
                this.passwordError = 'Passwords do not match.';
            }
        }
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>